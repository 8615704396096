@import "../global/___variables"

.slice.highlights
    .subtitle-w
        display flex
        justify-content center
        .toggle-nav
            position relative
            margin-right 10px
            height 34px
            width 34px
            cursor pointer
            border 1px solid #e1e1e1
            display none
            +below(1024px)
                display block
            .icon-nav
                position absolute
                left 6px
                top 15px
                span,
                span:before,
                span:after
                    cursor pointer
                    border-radius 1px
                    height 2px
                    width 20px
                    background #636363 + 15%
                    position absolute
                    display block
                    content ''
                    transition all 300ms ease-in-out
                span:before
                    top -7px
                span:after
                    bottom -7px
                &.active span
                    background-color transparent
                    &:before,
                    &:after
                        top 0
                    &:before
                        transform rotate(45deg)
                    &:after
                        transform rotate(-45deg)
    .slice-i
        border-top: 1px solid #e1e1e1
        .highlights-select
            padding 0 30px
            lost-utility clearfix
            +below(1200px)
                padding 0 15px
            +below(1024px)
                display none
            .highlight-select:hover
            .highlight-select.active
                background-color: #34495e
                cursor pointer
                &:after
                    border-color: #34495e transparent transparent transparent
            .highlight-select
                lost-column 1/6 6 30px
                position relative
                cursor pointer
                display flex
                flex-direction column
                background: #9da4aa
                padding 25px 5px 15px
                align-items: center
                &:after
                    content: ''
                    position: absolute
                    top: 100%
                    left: 50%
                    transform: translateX(-50%)
                    width: 0
                    height: 0
                    border-style: solid
                    border-width: 10px 12px 0 12px
                    border-color: transparent transparent transparent transparent
                +below(1200px)
                    lost-column 1/6 6 20px
                    padding 15px 5px 15px
                +below(1024px)
                    lost-column 1/6 6 10px
                +below(992px)
                    lost-column 1/3 3 10px
                    margin-bottom 10px
                    &:after
                        border none
                +below(524px)
                    lost-column 1/2 2 10px
                .select-icon
                    margin-bottom 10px
                    +below(992px)
                        img
                            width 90%
                .select-title
                    display flex
                    align-items flex-end
                    color white
                    font-weight bold
                    text-transform uppercase
                    font-size 13px
                    text-align center
                    line-height 13px
                    min-height 40px
                    margin 0
                    +below(1200px)
                        font-weight normal
                    +below(992px)
                        min-height 24px
                    +below(524px)
                        min-height 28px
                    +below(370px)
                        min-height 40px
        .highlight-w
            .highlight.active
                display block
            .highlight
                display none
                width 100%
                margin-top 20px
