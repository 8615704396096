.slice.highlights .subtitle-w {
  display: flex;
  justify-content: center;
}
.slice.highlights .subtitle-w .toggle-nav {
  position: relative;
  margin-right: 10px;
  height: 34px;
  width: 34px;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  display: none;
}
@media only screen and (max-width: 1024px) {
  .slice.highlights .subtitle-w .toggle-nav {
    display: block;
  }
}
.slice.highlights .subtitle-w .toggle-nav .icon-nav {
  position: absolute;
  left: 6px;
  top: 15px;
}
.slice.highlights .subtitle-w .toggle-nav .icon-nav span,
.slice.highlights .subtitle-w .toggle-nav .icon-nav span:before,
.slice.highlights .subtitle-w .toggle-nav .icon-nav span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 20px;
  background: #7a7a7a;
  position: absolute;
  display: block;
  content: '';
  transition: all 300ms ease-in-out;
}
.slice.highlights .subtitle-w .toggle-nav .icon-nav span:before {
  top: -7px;
}
.slice.highlights .subtitle-w .toggle-nav .icon-nav span:after {
  bottom: -7px;
}
.slice.highlights .subtitle-w .toggle-nav .icon-nav.active span {
  background-color: transparent;
}
.slice.highlights .subtitle-w .toggle-nav .icon-nav.active span:before,
.slice.highlights .subtitle-w .toggle-nav .icon-nav.active span:after {
  top: 0;
}
.slice.highlights .subtitle-w .toggle-nav .icon-nav.active span:before {
  transform: rotate(45deg);
}
.slice.highlights .subtitle-w .toggle-nav .icon-nav.active span:after {
  transform: rotate(-45deg);
}
.slice.highlights .slice-i {
  border-top: 1px solid #e1e1e1;
}
.slice.highlights .slice-i .highlights-select {
  padding: 0 30px;
  lost-utility: clearfix;
}
@media only screen and (max-width: 1200px) {
  .slice.highlights .slice-i .highlights-select {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 1024px) {
  .slice.highlights .slice-i .highlights-select {
    display: none;
  }
}
.slice.highlights .slice-i .highlights-select .highlight-select:hover,
.slice.highlights .slice-i .highlights-select .highlight-select.active {
  background-color: #34495e;
  cursor: pointer;
}
.slice.highlights .slice-i .highlights-select .highlight-select:hover:after,
.slice.highlights .slice-i .highlights-select .highlight-select.active:after {
  border-color: #34495e transparent transparent transparent;
}
.slice.highlights .slice-i .highlights-select .highlight-select {
  lost-column: 1/6 6 30px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background: #9da4aa;
  padding: 25px 5px 15px;
  align-items: center;
}
.slice.highlights .slice-i .highlights-select .highlight-select:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 12px 0 12px;
  border-color: transparent transparent transparent transparent;
}
@media only screen and (max-width: 1200px) {
  .slice.highlights .slice-i .highlights-select .highlight-select {
    lost-column: 1/6 6 20px;
    padding: 15px 5px 15px;
  }
}
@media only screen and (max-width: 1024px) {
  .slice.highlights .slice-i .highlights-select .highlight-select {
    lost-column: 1/6 6 10px;
  }
}
@media only screen and (max-width: 992px) {
  .slice.highlights .slice-i .highlights-select .highlight-select {
    lost-column: 1/3 3 10px;
    margin-bottom: 10px;
  }
  .slice.highlights .slice-i .highlights-select .highlight-select:after {
    border: none;
  }
}
@media only screen and (max-width: 524px) {
  .slice.highlights .slice-i .highlights-select .highlight-select {
    lost-column: 1/2 2 10px;
  }
}
.slice.highlights .slice-i .highlights-select .highlight-select .select-icon {
  margin-bottom: 10px;
}
@media only screen and (max-width: 992px) {
  .slice.highlights .slice-i .highlights-select .highlight-select .select-icon img {
    width: 90%;
  }
}
.slice.highlights .slice-i .highlights-select .highlight-select .select-title {
  display: flex;
  align-items: flex-end;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  text-align: center;
  line-height: 13px;
  min-height: 40px;
  margin: 0;
}
@media only screen and (max-width: 1200px) {
  .slice.highlights .slice-i .highlights-select .highlight-select .select-title {
    font-weight: normal;
  }
}
@media only screen and (max-width: 992px) {
  .slice.highlights .slice-i .highlights-select .highlight-select .select-title {
    min-height: 24px;
  }
}
@media only screen and (max-width: 524px) {
  .slice.highlights .slice-i .highlights-select .highlight-select .select-title {
    min-height: 28px;
  }
}
@media only screen and (max-width: 370px) {
  .slice.highlights .slice-i .highlights-select .highlight-select .select-title {
    min-height: 40px;
  }
}
.slice.highlights .slice-i .highlight-w .highlight.active {
  display: block;
}
.slice.highlights .slice-i .highlight-w .highlight {
  display: none;
  width: 100%;
  margin-top: 20px;
}
